<template>
  <div class="container">
    <router-link class="navbar-logo" to="/" @click="closeNav">
    </router-link>
    <div class="navbar-icon" @click="openNav" :class="{ 'active': navActive }" ref="targetNavIcon">
      <Icon icon="ph:dots-three-outline-vertical-fill"></Icon>
    </div>
    <transition name="fade">
      <ul class="navbar-menu" :class="{ 'active': navActive }">
        <li class="nav-item" @click="closeNav">
          <router-link class="nav-link" to="/about">
            {{ $t('menu.aboutUs') }}
          </router-link>
        </li>
        <li class="nav-item" @click="closeNav">
          <router-link class="nav-link" to="/service">
            {{ $t('menu.service') }}
          </router-link>
          <ul class="navbar-sub-menu">
            <li class="nav-item" @click="closeNav">
              <router-link to="/service/IM" :key="$route.fullPath">
                {{ $t('service.title.iam') }}
              </router-link>
            </li>
            <li class="nav-item" @click="closeNav">
              <router-link to="/service/SSO" :key="$route.fullPath">
                {{ $t('service.title.sso') }}
              </router-link>
            </li>
            <li class="nav-item" @click="closeNav">
              <router-link to="/service/OTP" :key="$route.fullPath">
                {{ $t('service.title.otp') }}
              </router-link>
            </li>
            <li class="nav-item" @click="closeNav">
              <router-link to="/service/ISIGN" :key="$route.fullPath">
                {{ $t('service.title.isign') }}
              </router-link>
            </li>
            <li class="nav-item" @click="closeNav">
              <router-link to="/service/SOF" :key="$route.fullPath">
                {{ $t('service.title.sof') }}
              </router-link>
            </li>
            <li class="nav-item" @click="closeNav">
              <router-link to="/service/DAMOS" :key="$route.fullPath">
                {{ $t('service.title.damos') }}
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" @click="closeNav">
          <router-link class="nav-link" to="/contact">
            {{ $t('menu.contact') }}
          </router-link>
        </li>
        <li class="lang-wrap" @click="changeLocale">
          <Icon icon="ph:globe-thin"></Icon>
          {{ $t('lang') }}
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
import { Icon } from '@iconify/vue'

export default {
  name: 'headerNav',
  components: { Icon },
  data () {
    return {
      navActive: false
    }
  },
  methods: {
    openNav: function () {
      this.navActive = true
      this.navShow = true
      const navButton = this.$refs.targetNavIcon
      if (navButton.classList.contains('active')) {
        this.navActive = false
      }
    },
    closeNav: function () {
      this.navActive = false
    },
    changeLocale () {
      if (this.$i18n.locale === 'ko') return (this.$i18n.locale = 'en')
      this.$i18n.locale = 'ko'
    }
  }
}

</script>
