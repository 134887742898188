<template>
  <div class="service wrap">
    <SlotPageVisual>
      <template #visual-bg>
        <div class="img service"></div>
      </template>
      <template #contents>
        <h1 class="visual-title">
          <span data-aos="fade">Solution<br /></span>
          <span class="hl" data-aos="fade-down" data-aos-delay="500">& </span>
          <span data-aos="fade-down" data-aos-delay="1000">Service</span>
        </h1>
        <p class="visual-summary">
          <span data-aos="fade-up" data-aos-delay="1500">
            {{ $t('service.desc.01') }}
          </span>
          <br />
          <span data-aos="fade-up" data-aos-delay="2000">
            {{ $t('service.desc.02') }}
          </span>
        </p>
      </template>
    </SlotPageVisual>
    <section class="service-menu-wrap" data-aos="fade">
      <div class="service-menu im">
        <div class="section-contents">
          <h4 class="sub-title hl">
            {{ $t('service.menu.iam.subTitle') }}
          </h4>
          <h1 class="title">
            {{ $t('service.title.iam') }}
          </h1>
          <div class="divider"></div>
          <p class="summary">
            {{ $t('service.menu.iam.desc') }}
          </p>
          <router-link to="/service/IM" :key="$route.fullPath">
            <button type="button" class="button big line">
              {{ $t('more') }}
            </button>
          </router-link>
        </div>
        <div class="layer"></div>
      </div>
      <div class="service-menu sso">
        <div class="section-contents">
          <h4 class="sub-title hl">
            {{ $t('service.menu.sso.subTitle') }}
          </h4>
          <h1 class="title">
            {{ $t('service.title.sso') }}
          </h1>
          <div class="divider"></div>
          <p class="summary">
            {{ $t('service.menu.sso.desc') }}
          </p>
          <router-link to="/service/SSO" :key="$route.fullPath">
            <button type="button" class="button big line">
              {{ $t('more') }}
            </button>
          </router-link>
        </div>
        <div class="layer"></div>
      </div>
      <div class="service-menu otp">
        <div class="section-contents">
          <h4 class="sub-title hl">
            {{ $t('service.menu.otp.subTitle') }}
          </h4>
          <h1 class="title">
            {{ $t('service.title.otp') }}
          </h1>
          <div class="divider"></div>
          <p class="summary">
            {{ $t('service.menu.otp.desc') }}
          </p>
          <router-link to="/service/OTP" :key="$route.fullPath">
            <button type="button" class="button big line">
              {{ $t('more') }}
            </button>
          </router-link>
        </div>
        <div class="layer"></div>
      </div>
      <div class="service-menu isign">
        <div class="section-contents">
          <h4 class="sub-title hl">
            {{ $t('service.menu.isign.subTitle') }}
          </h4>
          <h1 class="title">
            {{ $t('service.title.isign') }}
          </h1>
          <div class="divider"></div>
          <p class="summary">
            {{ $t('service.menu.isign.desc') }}
          </p>
          <router-link to="/service/ISIGN" :key="$route.fullPath">
            <button type="button" class="button big line">
              {{ $t('more') }}
            </button>
          </router-link>
        </div>
        <div class="layer"></div>
      </div>
      <div class="service-menu sof">
        <div class="section-contents">
          <h4 class="sub-title hl">
            {{ $t('service.menu.sof.subTitle') }}
          </h4>
          <h1 class="title">
            {{ $t('service.title.sof') }}
          </h1>
          <div class="divider"></div>
          <p class="summary">
            {{ $t('service.menu.sof.desc') }}
          </p>
          <router-link to="/service/SOF" :key="$route.fullPath">
            <button type="button" class="button big line">
              {{ $t('more') }}
            </button>
          </router-link>
        </div>
        <div class="layer"></div>
      </div>
      <div class="service-menu damos">
        <div class="section-contents">
          <h4 class="sub-title hl">
            {{ $t('service.menu.damos.subTitle') }}
          </h4>
          <h1 class="title">
            {{ $t('service.title.damos') }}
          </h1>
          <div class="divider"></div>
          <p class="summary">
            {{ $t('service.menu.damos.desc') }}
          </p>
          <router-link to="/service/DAMOS" :key="$route.fullPath">
            <button type="button" class="button big line">
              {{ $t('more') }}
            </button>
          </router-link>
        </div>
        <div class="layer"></div>
      </div>
    </section>
  </div>
</template>
<script>
import SlotPageVisual from '@/components/SlotPageVisual'

export default {
  name: 'ServiceView',
  components: { SlotPageVisual }
}
</script>
