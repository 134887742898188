<template>
  <div class="service detail wrap">
    <div class="text-bg damos">
      <div class="text-wrap">
        <p>
          {{ $t('service.detail.damos.lineText.01') }}
        </p>
        <p>
          {{ $t('service.detail.damos.lineText.02') }}
        </p>
        <p>
          {{ $t('service.detail.damos.lineText.03') }}
        </p>
      </div>
    </div>
    <HalfPageLayout class="damos title">
      <template #half-left>
        <div class="damos img"></div>
      </template>
      <template #half-right>
        <div class="inner-contents" style="margin-top: -5vh">
          <div class="contents-title">
            <h3 style="line-height: 1.1">
              {{ $t('service.title.damos') }}
            </h3>
            <h6>NS Dynamic Data Hub</h6>
            <h4>
              {{ $t('service.detail.damos.subTitle') }}
            </h4>
          </div>
          <div class="contents-list flex mb-50">
            <h5 class="circle">
              {{ $t('service.detail.damos.circle.detect') }}
            </h5>
            <h5 class="circle">
              {{ $t('service.detail.damos.circle.monitoring') }}
            </h5>
            <h5 class="circle">
              {{ $t('service.detail.damos.circle.operator') }}
            </h5>
          </div>
          <h5>
            {{ $t('service.detail.damos.desc') }}
          </h5>
        </div>
      </template>
    </HalfPageLayout>
    <HalfPageLayout class="damos summary">
      <template #half-left>
        <div class="inner-contents">
          <div class="section-title" data-aos="fade">
            <h3 class="hl">
              {{ $t('service.detail.damos.firstPage.title') }}
            </h3>
          </div>
          <div class="section-contents">
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:detective-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.damos.firstPage.contents.01.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.damos.firstPage.contents.01.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="700">
              <Icon icon="ph:monitor-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.damos.firstPage.contents.02.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.damos.firstPage.contents.02.desc') }}
                </dd>              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="900">
              <Icon icon="ph:sliders-horizontal-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.damos.firstPage.contents.03.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.damos.firstPage.contents.03.desc') }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </template>
      <template #half-right>
        <div class="img visual-01"></div>
      </template>
    </HalfPageLayout>
    <HalfPageLayout class="damos summary">
      <template #half-left>
        <div class="img visual-02"></div>
      </template>
      <template #half-right>
        <div class="animation-bg">
          <div class="gear">
            <SvgGear />
          </div>
          <div class="gear">
            <SvgGear />
          </div>
        </div>
        <div class="inner-contents">
          <div class="section-title" data-aos="fade">
            <h3 class="hl">
              {{ $t('service.detail.damos.secondPage.title') }}
            </h3>
          </div>
          <div class="section-contents">
            <div class="contents-list icon" data-aos="fade" data-aos-delay="500">
              <Icon icon="ph:password-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.damos.secondPage.contents.01.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.damos.secondPage.contents.01.desc') }}
                </dd>
              </dl>
            </div>
            <div class="contents-list icon" data-aos="fade" data-aos-delay="700">
              <Icon icon="ph:arrows-clockwise-thin"></Icon>
              <dl>
                <dt>
                  {{ $t('service.detail.damos.secondPage.contents.02.title') }}
                </dt>
                <dd>
                  {{ $t('service.detail.damos.secondPage.contents.02.desc') }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </template>
    </HalfPageLayout>
    <section class="section damos benefit bg-dark">
      <h2 class="hl section-title">
        {{ $t('service.detail.damos.benefit.title') }}
      </h2>
      <div class="section-contents">
        <div class="inner-contents">
          <div class="contents-list">
            <dl data-aos="zoom-in">
              <div class="img damos"></div>
              <dt>
                {{ $t('service.detail.damos.benefit.contents.01.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.damos.benefit.contents.01.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img damos"></div>
              <dt>
                {{ $t('service.detail.damos.benefit.contents.02.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.damos.benefit.contents.02.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img damos"></div>
              <dt>
                {{ $t('service.detail.damos.benefit.contents.03.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.damos.benefit.contents.03.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img damos"></div>
              <dt>
                {{ $t('service.detail.damos.benefit.contents.04.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.damos.benefit.contents.04.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img damos"></div>
              <dt>
                {{ $t('service.detail.damos.benefit.contents.05.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.damos.benefit.contents.05.desc') }}
              </dd>
            </dl>
            <dl data-aos="zoom-in">
              <div class="img damos"></div>
              <dt>
                {{ $t('service.detail.damos.benefit.contents.06.title') }}
              </dt>
              <dd>
                {{ $t('service.detail.damos.benefit.contents.06.desc') }}
              </dd>
            </dl>
          </div>
        </div>
        <router-link to="/contact" class="button big line">
          {{ $t('service.detail.contact') }}
        </router-link>
      </div>
    </section>
  </div>

</template>

<script>
import HalfPageLayout from '@/components/HalfPageLayout'
import { Icon } from '@iconify/vue'
import SvgGear from '@/components/SvgGear'

export default {
  name: 'ServiceDetailNSync',
  components: { HalfPageLayout, Icon, SvgGear },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
