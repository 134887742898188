<template>
  <section class="section bg-grey">
    <SectionTitle :title="$t('main.customers.title')" :summary="$t('main.customers.desc')"/>
    <div class="section-contents">
      <div class="horizon-scroll customers">
        <div class="item" :key="customer" v-for="customer in customers" data-aos="flip-left"  data-aos-delay="500">
          <img :src="customer.image" :alt="customer.alt">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/SectionTitle'
export default {
  name: 'CustomerList',
  components: { SectionTitle },
  data () {
    return {
      title: 'Customers',
      summary: '낸드소프트와 신뢰관계를 맺은 파트너 기업입니다.',
      customers: [
        {
          image: require('@/assets/images/customers/dgb_logo.png'),
          alt: '대구은행'
        },
        {
          image: require('@/assets/images/customers/jejushinhwa_logo.png'),
          alt: '제주신라월드'
        },
        {
          image: require('@/assets/images/customers/keris_logo.png'),
          alt: '한국교육학술정보원'
        },
        {
          image: require('@/assets/images/customers/lgcns_logo.png'),
          alt: 'LG CNS'
        },
        {
          image: require('@/assets/images/customers/lottetour_logo.png'),
          alt: '롯데관광'
        },
        {
          image: require('@/assets/images/customers/nets_logo.png'),
          alt: '넷츠'
        },
        {
          image: require('@/assets/images/customers/siltron_logo.png'),
          alt: 'SK실트론'
        },
        {
          image: require('@/assets/images/customers/tkg_logo.png'),
          alt: 'TKG태광'
        },
        {
          image: require('@/assets/images/customers/bnk_logo.svg'),
          alt: '부산은행'
        },
        {
          image: require('@/assets/images/customers/dgist_logo.png'),
          alt: 'DGIST'
        },
        {
          image: require('@/assets/images/customers/tdg_logo.png'),
          alt: 'TDG'
        },
        {
          image: require('@/assets/images/customers/pnp_logo.png'),
          alt: '피앤피플 사람들과 사람들'
        },
        {
          image: require('@/assets/images/customers/zenith_logo.png'),
          alt: '제니스 클라우드'
        },
        {
          image: require('@/assets/images/customers/sc_logo.png'),
          alt: 'SC 제일은행'
        },
        {
          image: require('@/assets/images/customers/dns_logo.png'),
          alt: 'DN 솔루션즈'
        },
        {
          image: require('@/assets/images/customers/sjg_logo.png'),
          alt: 'SJG 세종'
        },
        {
          image: require('@/assets/images/customers/penta_logo.png'),
          alt: '펜타 시큐리티'
        },
        {
          image: require('@/assets/images/customers/hdsh_logo.png'),
          alt: '현대삼호중공업'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
