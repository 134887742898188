<template>
  <section class="section bg-light" id="ourService">
    <SectionTitle :title="$t('main.ourService.title')" :summary="$t('main.ourService.summary')" />
    <div class="section-contents" data-aos="fade-up" data-aos-delay="500">
      <div class="mini buttons">
        <button type="button" class="button" :class="{'active': imActive}" @click="toService" id="im">
          {{ $t('service.title.iam') }}
        </button>
        <button type="button" class="button" :class="{'active': ssoActive}" @click="toService" id="sso">
          {{ $t('service.title.sso') }}
        </button>
        <button type="button" class="button" :class="{'active': otpActive}" @click="toService" id="otp">
          {{ $t('service.title.otp') }}
        </button>
        <button type="button" class="button" :class="{'active': isignActive}" @click="toService" id="isign">
          {{ $t('service.title.isign') }}
        </button>
        <button type="button" class="button" :class="{'active': sofActive}" @click="toService" id="sof">
          {{ $t('service.title.sof') }}
        </button>
        <button type="button" class="button" :class="{'active': damosActive}" @click="toService" id="damos">
          {{ $t('service.title.damos') }}
        </button>
      </div>
      <div class="horizon-scroll horizontal slide">
        <div class="item" id="itemIM">
          <div class="horizon-visual im">
            <div class="img"></div>
          </div>
          <div class="horizon-visual-text">
            <ContentsTitle :title="$t('service.title.iam')" :summary="$t('service.summary.iam')" />
            <div class="tag">
              <span class="item">{{ $t('main.ourService.tag.iam.01') }}</span>
              <span class="item">{{ $t('main.ourService.tag.iam.02') }}</span>
              <span class="item">{{ $t('main.ourService.tag.iam.03') }}</span>
              <span class="item">{{ $t('main.ourService.tag.iam.04') }}</span>
              <span class="item">{{ $t('main.ourService.tag.iam.05') }}</span>
            </div>
            <router-link to="/service/IM" :key="$route.fullPath">
              <button type="button" class="button big line">
                {{ $t('more') }}
              </button>
            </router-link>
          </div>
        </div>
        <div class="item" id="itemSSO">
          <div class="horizon-visual sso">
            <div class="img"></div>
          </div>
          <div class="horizon-visual-text">
            <ContentsTitle :title="$t('service.title.sso')" :summary="$t('service.summary.sso')" />
            <div class="tag">
              <span class="item">{{ $t('main.ourService.tag.sso.01') }}</span>
              <span class="item">{{ $t('main.ourService.tag.sso.02') }}</span>
              <span class="item">{{ $t('main.ourService.tag.sso.03') }}</span>
              <span class="item">{{ $t('main.ourService.tag.sso.04') }}</span>
            </div>
            <router-link to="/service/SSO" :key="$route.fullPath">
              <button type="button" class="button big line">
                {{ $t('more') }}
              </button>
            </router-link>
          </div>
        </div>
        <div class="item" id="itemOTP">
          <div class="horizon-visual otp">
            <div class="img"></div>
          </div>
          <div class="horizon-visual-text">
            <ContentsTitle :title="$t('service.title.otp')" :summary="$t('service.summary.otp')" />
            <div class="tag">
              <span class="item">{{ $t('main.ourService.tag.otp.01') }}</span>
              <span class="item">{{ $t('main.ourService.tag.otp.02') }}</span>
              <span class="item">{{ $t('main.ourService.tag.otp.03') }}</span>
              <span class="item">{{ $t('main.ourService.tag.otp.04') }}</span>
              <span class="item">{{ $t('main.ourService.tag.otp.05') }}</span>
            </div>
            <router-link to="/service/OTP" :key="$route.fullPath">
              <button type="button" class="button big line">
                {{ $t('more') }}
              </button>
            </router-link>
          </div>
        </div>
        <div class="item" id="itemISIGN">
          <div class="horizon-visual isign">
            <div class="img"></div>
          </div>
          <div class="horizon-visual-text">
            <ContentsTitle :title="$t('service.title.isign')" :summary="$t('service.summary.isign')" />
            <div class="tag">
              <span class="item">{{ $t('main.ourService.tag.isign.01') }}</span>
              <span class="item">{{ $t('main.ourService.tag.isign.02') }}</span>
              <span class="item">{{ $t('main.ourService.tag.isign.03') }}</span>
              <span class="item">{{ $t('main.ourService.tag.isign.04') }}</span>
            </div>
            <router-link to="/service/ISIGN" :key="$route.fullPath">
              <button type="button" class="button big line">
                {{ $t('more') }}
              </button>
            </router-link>
          </div>
        </div>
        <div class="item" id="itemSOF">
          <div class="horizon-visual sof">
            <div class="img"></div>
          </div>
          <div class="horizon-visual-text">
            <ContentsTitle :title="$t('service.title.sof')" :summary="$t('service.summary.sof')" />
            <div class="tag">
              <span class="item">{{ $t('main.ourService.tag.sof.01') }}</span>
              <span class="item">{{ $t('main.ourService.tag.sof.02') }}</span>
              <span class="item">{{ $t('main.ourService.tag.sof.03') }}</span>
              <span class="item">{{ $t('main.ourService.tag.sof.04') }}</span>
              <span class="item">{{ $t('main.ourService.tag.sof.05') }}</span>
            </div>
            <router-link to="/service/SOF" :key="$route.fullPath">
              <button type="button" class="button big line">
                {{ $t('more') }}
              </button>
            </router-link>
          </div>
        </div>
        <div class="item" id="itemDAMOS">
          <div class="horizon-visual damos">
            <div class="img"></div>
          </div>
          <div class="horizon-visual-text">
            <ContentsTitle :title="$t('service.title.damos')" :summary="$t('service.summary.damos')" />
            <div class="tag">
              <span class="item">{{ $t('main.ourService.tag.damos.01') }}</span>
              <span class="item">{{ $t('main.ourService.tag.damos.02') }}</span>
              <span class="item">{{ $t('main.ourService.tag.damos.03') }}</span>
              <span class="item">{{ $t('main.ourService.tag.damos.04') }}</span>
              <span class="item">{{ $t('main.ourService.tag.damos.05') }}</span>
              <span class="item">{{ $t('main.ourService.tag.damos.06') }}</span>
            </div>
            <router-link to="/service/DAMOS" :key="$route.fullPath">
              <button type="button" class="button big line">
                {{ $t('more') }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/SectionTitle'
import ContentsTitle from '@/components/ContentsTitle'

export default {
  name: 'OurService',
  components: { ContentsTitle, SectionTitle },
  data () {
    return {
      tagListIM: ['통합계정권한관리', '동기화관리', '사용자계정관리', '사용자권한관리', '사용자그룹관리'],
      tagListSSO: ['통합계정인증', '다중어플리케이션', '인증관리', '싱글사인온'],
      tagListOTP: ['다중인증', '보안강화', '정보보호', '다양한인증방식', '생체인증'],
      tagListSOF: ['스마트오피스', '좌석예약', '미팅룸관리', '업무시간관리', '공간효율'],
      tagListDAMOS: ['통합솔루션', 'AD모니터링', '위험감지', '보안강화', '권한관리', '패스워드관리'],
      imActive: true,
      ssoActive: false,
      isignActive: false,
      otpActive: false,
      sofActive: false,
      damosActive: false

    }
  },
  methods: {
    toService: function () {
      const tagId = event.currentTarget.id
      if (tagId === 'im') {
        const serviceLocation = document.querySelector('#itemIM').offsetLeft
        document.querySelector('.horizon-scroll').scrollTo({ left: serviceLocation, behavior: 'smooth' })
        this.imActive = true
        this.ssoActive = false
        this.otpActive = false
        this.sofActive = false
        this.damosActive = false
      } else if (tagId === 'sso') {
        const serviceLocation = document.querySelector('#itemSSO').offsetLeft
        document.querySelector('.horizon-scroll').scrollTo({ left: serviceLocation, behavior: 'smooth' })
        this.ssoActive = true
        this.imActive = false
        this.isignActive = false
        this.otpActive = false
        this.sofActive = false
        this.damosActive = false
      } else if (tagId === 'isign') {
        const serviceLocation = document.querySelector('#itemISIGN').offsetLeft
        document.querySelector('.horizon-scroll').scrollTo({ left: serviceLocation, behavior: 'smooth' })
        this.isignActive = true
        this.imActive = false
        this.ssoActive = false
        this.otpActive = false
        this.sofActive = false
        this.damosActive = false
      } else if (tagId === 'otp') {
        const serviceLocation = document.querySelector('#itemOTP').offsetLeft
        document.querySelector('.horizon-scroll').scrollTo({ left: serviceLocation, behavior: 'smooth' })
        this.otpActive = true
        this.imActive = false
        this.ssoActive = false
        this.isignActive = false
        this.sofActive = false
        this.damosActive = false
      } else if (tagId === 'sof') {
        const serviceLocation = document.querySelector('#itemSOF').offsetLeft
        document.querySelector('.horizon-scroll').scrollTo({ left: serviceLocation, behavior: 'smooth' })
        this.sofActive = true
        this.imActive = false
        this.ssoActive = false
        this.isignActive = false
        this.otpActive = false
        this.damosActive = false
      } else if (tagId === 'damos') {
        const serviceLocation = document.querySelector('#itemDAMOS').offsetLeft
        document.querySelector('.horizon-scroll').scrollTo({ left: serviceLocation, behavior: 'smooth' })
        this.damosActive = true
        this.imActive = false
        this.ssoActive = false
        this.isignActive = false
        this.otpActive = false
        this.sofActive = false
      }
    }
  }
}
</script>

<style scoped>

</style>
